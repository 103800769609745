import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero_image from"../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png"
const Hero = () => {
  const mobile = window.innerWidth<=768?true:false;
  return (
    <div className="hero">
      <div className="left-h">

      <Header/>
      <div className='the-best-ad'>
        <div></div>
        <span>The Best Fitness App in Town</span>
      </div>
      <div className='hero-text'>
        <div>
          <span className='stroke-text'>SHAPE </span><span>YOUR</span></div>
          <div>
            <span>IDEAL </span><span>BODY</span>
          </div>
      </div>
      <div className="figures">
        <div><span>+140</span><span>expert coaches</span>
        </div>
        <div><span>+978</span><span>members joined</span>
        </div>
        <div><span>+50</span><span>fitness programs</span>
        </div>
      </div>
      <div className="hero-buttons">
        <buttons className="btn">Get Started</buttons>
        <buttons className="btn">Learn More</buttons>
      </div>
      </div>
      <div className="right-h">
        <button className='btn'>Join Now</button>

        <div className='heart-rate'>
          <img src={Heart} alt=""/>
          <span>Heart Rate</span><span>113 bpm</span>
        </div>
        <img src={hero_image} alt="" className='hero_image'/>
        <img src={hero_image_back} alt="" className='hero_image_back'/>
        <div className="calories">
          <img src={Calories} alt="" />
          <div>
          <span>Calories Burned</span>
          <span>221 Kcal</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
