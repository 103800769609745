import React from 'react';
import './Header.css';
import Logo from '../../assets/logo.png';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <div className="header">
      <img src={Logo} alt="Logo" className="logo" />

      <ul className="header-menu">
        <li>
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
          <Link to="programs" smooth={true} duration={500}>
            Programs
          </Link>
        </li>
        <li>
          <Link to="why-us" smooth={true} duration={500}>
            Why Us
          </Link>
        </li>
        <li>
          <Link to="plans" smooth={true} duration={500}>
            Plans
          </Link>
        </li>
        <li>
          <Link to="testimonials" smooth={true} duration={500}>
            Testimonials
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Header;
