import React from 'react'
import "./Programs.css"
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/rightArrow.png'
const Programs = () => {
  return (
    <div className='Programs' id="Programs">
        <div className="programs-header">
            <span className='stroke-text'>Explore</span>
            <span>Our Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>
      <div className="program-categ">
        {programsData.map((program)=>(
            <div className="categ">
                {program.image}
                <span>{program.heading}</span><span>{program.details}</span>
                <div className="join-now"><span>Join Now</span><img src={RightArrow}/></div>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Programs
